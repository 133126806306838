import { PanelOptionsEditorBuilder } from '@grafana/data';
import { PanelSettings } from '../types';
import { FieldSelectEditor } from './FieldSelectEditor';
import { IconMapping } from './IconMapping';

export const optionsBuilder = (builder: PanelOptionsEditorBuilder<PanelSettings>) => {
  return (
    builder
      //Connection Mapping
      .addCustomEditor({
        path: 'dataMapping.nodeID',
        id: 'nodeID',
        editor: FieldSelectEditor,
        name: 'Node ID',
        category: ['Connection Mapping'],
        defaultValue: 'nodeID',
      })

      .addCustomEditor({
        path: 'dataMapping.xColumn',
        id: 'xColumn',
        editor: FieldSelectEditor,
        name: 'Node x Position',
        category: ['Connection Mapping'],
        defaultValue: 'x',
      })
      .addCustomEditor({
        path: 'dataMapping.yColumn',
        id: 'yColumn',
        editor: FieldSelectEditor,
        name: 'Node y Position',
        category: ['Connection Mapping'],
        defaultValue: 'y',
      })
      .addCustomEditor({
        path: 'dataMapping.lonColumn',
        id: 'lonColumn',
        editor: FieldSelectEditor,
        name: 'Node longitud coordinate',
        category: ['Connection Mapping'],
        defaultValue: 'lon',
      })
      .addCustomEditor({
        path: 'dataMapping.latColumn',
        id: 'latColumn',
        editor: FieldSelectEditor,
        name: 'Node latitud coordinate',
        category: ['Connection Mapping'],
        defaultValue: 'lat',
      })

      .addCustomEditor({
        path: 'dataMapping.targetColumn',
        id: 'targetColumn',
        name: 'Origin Node',
        category: ['Connection Mapping'],
        editor: FieldSelectEditor,
        defaultValue: 'source',
        //showIf: config => config.dataMapping.nodeID === undefined || config.dataMapping.nodeID === null,
      })

      .addCustomEditor({
        id: 'labelColumn',
        path: 'dataMapping.labelColumn',
        editor: FieldSelectEditor,
        name: 'Origin Node Label',
        category: ['Connection Mapping'],
        defaultValue: 'label',
      })

      .addCustomEditor({
        id: 'siteColumn',
        path: 'dataMapping.siteColumn',
        editor: FieldSelectEditor,
        name: 'Origin Node Location',
        category: ['Connection Mapping'],
        defaultValue: 'site',
      })

      .addCustomEditor({
        path: 'dataMapping.sourceColumn',
        id: 'sourceColumn',
        editor: FieldSelectEditor,
        name: 'Destination Node',
        category: ['Connection Mapping'],
        defaultValue: 'target',
      })

      .addCustomEditor({
        id: 'labelOutColumn',
        path: 'dataMapping.labelOutColumn',
        name: 'Target Node Label',
        editor: FieldSelectEditor,
        category: ['Connection Mapping'],
        defaultValue: 'labelOut',
      })

      .addCustomEditor({
        id: 'siteOutColumn',
        path: 'dataMapping.siteOutColumn',
        name: 'Target Node Site',
        editor: FieldSelectEditor,
        category: ['Connection Mapping'],
        defaultValue: 'siteOut',
      })

      .addCustomEditor({
        path: 'dataMapping.nodeGroup',
        id: 'nodeGroup',
        name: 'Node Group',
        category: ['Connection Mapping'],
        editor: FieldSelectEditor,
        defaultValue: 'nodeGroup',
      })

      .addCustomEditor({
        path: 'dataMapping.nodeIcon',
        id: 'nodeIcon',
        name: 'Node icon',
        description: 'Controls the icon used in the node and the headers uses in tables',
        category: ['Connection Mapping'],
        editor: FieldSelectEditor,
        defaultValue: 'nodeIcon',
      })

      .addCustomEditor({
        path: 'dataMapping.nodeVisible',
        id: 'nodeVisible',
        name: 'Node visible',
        description: 'Controls if the node was remove by user',
        category: ['Connection Mapping'],
        editor: FieldSelectEditor,
        defaultValue: 'nodeVisible',
      })

      .addCustomEditor({
        path: 'dataMapping.nodeType',
        id: 'nodeType',
        name: 'Node Type',
        description: 'This field indicates the type of node represented.',
        category: ['Connection Mapping'],
        editor: FieldSelectEditor,
        defaultValue: 'nodeType',
      })

      .addCustomEditor({
        path: 'dataMapping.extOrigin',
        id: 'extOrigin',
        name: 'External Origin Node',
        category: ['Connection Mapping'],
        editor: FieldSelectEditor,
        defaultValue: 'extOrigin',
      })

      .addCustomEditor({
        path: 'dataMapping.extTarget',
        id: 'extTarget',
        name: 'External Destination Node',
        category: ['Connection Mapping'],
        editor: FieldSelectEditor,
        defaultValue: 'extTarget',
      })

      .addCustomEditor({
        path: 'dataMapping.nodeDescriptionColumn',
        id: 'nodeDescriptionColumn',
        name: 'Node description',
        category: ['Connection Mapping'],
        editor: FieldSelectEditor,
        defaultValue: 'nodeDescription',
      })

      //Data Mapping
      .addBooleanSwitch({
        path: 'isL1Topology',
        name: 'is Phisical map?',
        description: 'If it is active, the map shows a physical topology',
        category: ['Data Mapping'],
        defaultValue: false,
      })
      .addBooleanSwitch({
        path: 'isStpTopology',
        name: 'is STP o LLDP map?',
        description: 'If it is active, the map shows an STP topology or LLDP Discovery',
        category: ['Data Mapping'],
        defaultValue: false,
		showIf: config => config.isL1Topology === false,
      })
      .addCustomEditor({
        path: 'dataMapping.isRoot',
        id: 'isRoot',
        name: 'is a STP root node?',
        category: ['Data Mapping'],
        editor: FieldSelectEditor,
        showIf: config => config.isStpTopology === true,
		defaultValue: 'isRoot',
      })
      .addBooleanSwitch({
        path: 'useTrafficMetric',
        name: 'Use traffic Metric',
        description: 'If it is active, the map shows traffic metric on links',
        category: ['Data Mapping'],
        defaultValue: false,
      })
      .addCustomEditor({
        id: 'trafficColumn',
        path: 'dataMapping.trafficColumn',
        name: 'Node Ingress Traffic',
        editor: FieldSelectEditor,
        category: ['Data Mapping'],
        defaultValue: 'trafficIn',
        showIf: config => config.useTrafficMetric === true,
      })
      .addCustomEditor({
        id: 'trafficOutColumn',
        path: 'dataMapping.trafficOutColumn',
        name: 'Node Egress Traffic',
        editor: FieldSelectEditor,
        category: ['Data Mapping'],
        defaultValue: 'trafficOut',
        showIf: config => config.useTrafficMetric === true,
      })

      .addBooleanSwitch({
        path: 'useMetricOne',
        name: 'Use Metric One',
        description: 'If it is active, the map shows traffic metric on links',
        category: ['Data Mapping'],
        defaultValue: false,
      })
      .addCustomEditor({
        id: 'metricOneColumn',
        path: 'dataMapping.metricOneColumn',
        name: 'Metric 1 Total (Max)',
        editor: FieldSelectEditor,
        category: ['Data Mapping'],
        showIf: config => config.useMetricOne === true,
        defaultValue: 'metricOne',
      })
      .addCustomEditor({
        id: 'metricOneActualColumn',
        path: 'dataMapping.metricOneActualColumn',
        name: 'Metric 1 Actual',
        editor: FieldSelectEditor,
        category: ['Data Mapping'],
        showIf: config => config.useMetricOne === true,
        defaultValue: 'metricOneActual',
      })
      .addCustomEditor({
        id: 'metricOneThresholdColumn',
        path: 'dataMapping.metricOneThresholdColumn',
        name: 'Metric 1 Threshold',
        editor: FieldSelectEditor,
        category: ['Data Mapping'],
        showIf: config => config.useMetricOne === true,
        defaultValue: 'metricOneThreshold',
      })

      .addBooleanSwitch({
        path: 'useMetricTwo',
        name: 'Use Metric Two',
        description: 'If it is active, the map shows traffic metric on links',
        category: ['Data Mapping'],
        defaultValue: false,
      })
      .addCustomEditor({
        id: 'metricTwoColumn',
        path: 'dataMapping.metricTwoColumn',
        name: 'Metric 2 Total (Max)',
        editor: FieldSelectEditor,
        category: ['Data Mapping'],
        showIf: config => config.useMetricTwo === true,
        defaultValue: 'metricTwo',
      })
      .addCustomEditor({
        id: 'metricTwoActualColumn',
        path: 'dataMapping.metricTwoActualColumn',
        name: 'Metric 2 Actual',
        editor: FieldSelectEditor,
        category: ['Data Mapping'],
        showIf: config => config.useMetricTwo === true,
        defaultValue: 'metricTwoActual',
      })
      .addCustomEditor({
        id: 'metricTwoThresholdColumn',
        path: 'dataMapping.metricTwoThresholdColumn',
        name: 'Metric 2 Threshold',
        editor: FieldSelectEditor,
        category: ['Data Mapping'],
        showIf: config => config.useMetricTwo === true,
        defaultValue: 'metricTwoThreshold',
      })

      .addCustomEditor({
        id: 'nodeSla',
        path: 'dataMapping.nodeSla',
        name: 'Node SLA (Last hour)',
        editor: FieldSelectEditor,
        category: ['Data Mapping'],
		showIf: config => config.isStpTopology === false,
        defaultValue: 'nodeSla',
      })
      .addCustomEditor({
        id: 'nodeSla',
        path: 'dataMapping.nodeSla',
        name: 'Bridge Priority',
        editor: FieldSelectEditor,
        category: ['Data Mapping'],
		showIf: config => config.isStpTopology === true,
        defaultValue: 'priority',
      })

      .addCustomEditor({
        id: 'descriptionColumn',
        path: 'dataMapping.descriptionColumn',
        name: 'Ports description',
        editor: FieldSelectEditor,
        category: ['Data Mapping'],
        defaultValue: 'description',
      })

      .addCustomEditor({
        id: 'userColumn',
        path: 'dataMapping.userColumn',
        name: 'Panel user_id query field',
        editor: FieldSelectEditor,
        category: ['General Settings'],
        defaultValue: 'user',
      })

      //General Settings
      .addTextInput({
        path: 'map',
        name: 'Map name',
        category: ['General Settings'],
        defaultValue: '${__dashboard.uid}',
      })
      .addTextInput({
        path: 'api',
        name: 'API URL',
        category: ['General Settings'],
        defaultValue: '${api}',
      })
      .addTextInput({
        path: 'groupVariable',
        name: 'Group Variable name',
        description: 'Variable to modify when a group is selected',
        category: ['General Settings'],
        defaultValue: '',
      })
      .addTextInput({
        path: 'nodeVariable',
        name: 'Node Variable name',
        description: 'Variable to modify when a node is selected using the node name',
        category: ['General Settings'],
        defaultValue: '',
      })
      .addTextInput({
        path: 'edgeVariable',
        name: 'Edge Variable name',
        description: 'Variable to modify when a edge is selected using the edge name',
        category: ['General Settings'],
        defaultValue: '',
      })
      .addSelect({
        path: 'layoutFormat',
        name: 'Layout type',
        description: 'This setting controls the layout algoritm uses by the map',
        category: ['General Settings'],
        settings: {
          options: [
            { value: 'preset', label: 'preset' },
            { value: 'cola', label: 'CoLa (Constraint-Based Layout)' },
            { value: 'dagre', label: 'DAG (Directed Acyclic Graph)' },
            { value: 'avsdf', label: 'AVSDF (Adjacent Vertex Smallest Degree First)' },
            { value: 'cise', label: 'CiSE (Circular Spring Embedder)' },
            { value: 'fcose', label: 'CoSE (Compound Spring Embedder)' },
          ],
        },
        defaultValue: 'cola',
      })
      .addBooleanSwitch({
        path: 'addMap',
        name: 'add GeoMap as background?',
        description: 'If it is active, the nodes uses lat/lon as coordinates to show it in a geoMap',
        category: ['General Settings'],
        defaultValue: false,
		showIf: config => config.layoutFormat === 'preset',
      })
      .addTextInput({
        path: 'addMapVariable',
        name: 'GeoMap Control variable name',
	    description: 'Optional variable name to control show Map button in tollbar. The button is hide if it is empty.',
		category: ['General Settings'],
        defaultValue: '',
	    showIf: config => config.layoutFormat === 'preset' && config.addMap === true,
      })
      .addSelect({
        path: 'mapSource',
        name: 'Map type',
        description: 'This setting controls the map tiles type',
        category: ['General Settings'],
        showIf: config => config.layoutFormat === 'preset' && config.addMap === true,
		settings: {
          options: [
            { value: 'World_Street_Map', label: 'World Street Map' },
            { value: 'World_Imagery', label: 'World Imagery' },
            { value: 'World_Physical_Map', label: 'World Physical)' },
            { value: 'World_Topo_Map', label: 'World Topographic' },
          ],
        },
        defaultValue: 'World_Street_Map',
      })
      .addNumberInput({
        path: 'initialCyZoom',
        name: 'Initial Zoom',
        category: ['General Settings'],
        min: 0.25,
        max: 3,
		defaultValue: 1.5,
		showIf: config => config.addMap !== true,
      })
      .addNumberInput({
        path: 'initialX',
        name: 'Initial X position',
        category: ['General Settings'],
        defaultValue: 0,
		showIf: config => config.layoutFormat === 'preset' && config.addMap !== true,
      })
      .addNumberInput({
        path: 'initialY',
        name: 'Initial Y position',
        category: ['General Settings'],
		defaultValue: 0,
		showIf: config => config.layoutFormat === 'preset' && config.addMap !== true,
      })
      .addNumberInput({
        path: 'initialZoom',
        name: 'Initial Zoom (GeoMap Mode)',
        category: ['General Settings'],
        min: 3,
        max: 20,
		defaultValue: 3,
		showIf: config => config.layoutFormat === 'preset' && config.addMap === true,
      })
      .addNumberInput({
        path: 'initialLat',
        name: 'Initial Latitude',
        category: ['General Settings'],
		min: -90,
        max: 90,
        defaultValue: 0,
		showIf: config => config.layoutFormat === 'preset' && config.addMap === true,
      })
      .addNumberInput({
        path: 'initialLon',
        name: 'Initial Longitude',
        category: ['General Settings'],
        min: -180,
        max: 180,
		defaultValue: 0,
		showIf: config => config.layoutFormat === 'preset' && config.addMap === true,
      })
      .addBooleanSwitch({
        path: 'useZoomVariable',
        name: 'Use Zoom and coords variables',
	    category: ['General Settings'],
        defaultValue: false,
	    showIf: config => config.addMap === true,
      })
      .addTextInput({
        path: 'zoomVariable',
        name: 'zoom Variable',
	    category: ['General Settings'],
        defaultValue: 'zoom',
        showIf: (config) => config.useZoomVariable === true,
      })
      .addTextInput({
        path: 'zoomCyVariable',
        name: 'zoom Variable',
	    category: ['General Settings'],
        defaultValue: 'z',
        showIf: (config) => config.useZoomVariable === true,
      })
      .addTextInput({
        path: 'latVariable',
        name: 'Latitude Variable',
        category: ['General Settings'],
        defaultValue: 'lat',
        showIf: (config) => config.useZoomVariable === true,
      })
      .addTextInput({
        path: 'lonVariable',
        name: 'Longitude Variable',
	    category: ['General Settings'],
        defaultValue: 'lon',
        showIf: (config) => config.useZoomVariable === true,
      })
      .addTextInput({
        path: 'xVariable',
        name: 'Latitude Variable',
        category: ['General Settings'],
        defaultValue: 'x',
        showIf: (config) => config.useZoomVariable === true,
      })
      .addTextInput({
        path: 'yVariable',
        name: 'Longitude Variable',
	    category: ['General Settings'],
        defaultValue: 'y',
        showIf: (config) => config.useZoomVariable === true,
      })
      .addBooleanSwitch({
        path: 'infiniteSimulation',
        name: 'Infinite simulation?',
        description: 'If it is active, the simultaion never end',
        category: ['General Settings'],
        defaultValue: false,
		showIf: config => config.layoutFormat !== 'preset',
      })
      .addBooleanSwitch({
        path: 'showToolbar',
        name: 'Show toolbar',
        description: 'If it is active, the map shows its toolbar',
        category: ['General Settings'],
        defaultValue: true,
      })
      .addBooleanSwitch({
        path: 'showStatTables',
        name: 'Show Statistics Tables',
        description: 'If it is active, on node or link clic show statistics table',
        category: ['General Settings'],
        defaultValue: true,
      })
      .addBooleanSwitch({
        path: 'showEdgeStatus',
        name: 'Show link status',
        description: 'If it is active, show link status Alarm, Down, Up on Stat table',
        category: ['General Settings'],
        defaultValue: true,
      })
      .addBooleanSwitch({
        path: 'showConnectionStats',
        name: 'Show links Statistics',
        description: 'If it is active, shows (on links) tooltips with statistics',
        category: ['General Settings'],
        defaultValue: true,
      })
      .addBooleanSwitch({
        path: 'filterEmptyConnections',
        name: 'Filter Empty Data',
        description: 'If it is active, the map dosnt shows nodes without conections',
        category: ['General Settings'],
        defaultValue: false,
      })
      .addBooleanSwitch({
        path: 'showSLA',
        name: 'Show Asset SLA',
        description: 'Shows SLA metric when zoom in into the map',
        category: ['General Settings'],
        defaultValue: true,
      })
      .addBooleanSwitch({
        path: 'allCollapsed',
        name: 'All groups collapsed',
        description: 'Star the map with all the groups collapsed',
        category: ['General Settings'],
        defaultValue: false,
      })
      .addBooleanSwitch({
        path: 'initBlock',
        name: 'Grapgh blocked by default',
        description: 'If its active the user can move assets or modifiy the map',
        category: ['General Settings'],
        defaultValue: false,
      })
      .addBooleanSwitch({
        path: 'showBaselines',
        name: 'Show Thresholds',
        description: 'Shows on node a circle indicating if node thresholds were trespassed',
        category: ['General Settings'],
        defaultValue: true,
      })

      //Tables
      .addTextInput({
        path: 'sysHeader',
        name: 'SysInformation Table Header',
        category: ['Default Tables'],
        defaultValue: 'SysDecription,SysTime,SysLocation',
      })
      .addTextInput({
        path: 'nodeTitle',
        name: 'Node Table Title',
        category: ['Default Tables'],
        defaultValue: 'Enlaces físicos del Emplazamiento',
      })
      .addTextInput({
        path: 'nodeHeader',
        name: 'Node Table Header',
        category: ['Default Tables'],
        defaultValue: 'Destino,Activo,Emplazamiento,Tráfico(In/Out),Interfaces(Up/Down),-',
      })
      .addTextInput({
        path: 'edgeTitle',
        name: 'Links Table Title',
        category: ['Default Tables'],
        defaultValue: 'Interfaces físicas del enlace',
      })
      .addTextInput({
        path: 'edgeHeader',
        name: 'Links Table Header',
        category: ['Default Tables'],
        defaultValue: 'Origen,Slot/Puerto,Tipo,Destino,Slot/Puerto,Tipo,Estado',
      })

      //Appearance
      .addSelect({
        path: 'nodeShape',
        name: 'Node Shape',
        description: 'Controls the node shape uses by the map',
        category: ['Appearance'],
        settings: {
          options: [
            { value: 'circle', label: 'Circle' },
            { value: 'square', label: 'Square' },
          ],
        },
        defaultValue: 'circle',
      })
      .addSelect({
        path: 'iconShape',
        name: 'Icon Shape',
        category: ['Appearance'],
        description: 'Controls the icon shape uses by the map',
        settings: {
          options: [
            { value: 'circle', label: 'Circle' },
            { value: 'square', label: 'Square' },
          ],
        },
        defaultValue: 'circle',
      })
      .addNumberInput({
        path: 'style.healthyThreshold',
        name: 'Healthy Threshold',
        category: ['Appearance'],
        defaultValue: 90,
      })
      .addNumberInput({
        path: 'style.warningThreshold',
        name: 'Warning Threshold',
        category: ['Appearance'],
        defaultValue: 75,
      })
      .addColorPicker({
        path: 'style.healthyColor',
        name: 'Healthy Color',
        category: ['Appearance'],
        defaultValue: 'rgb(87, 148, 242)',
      })
      .addColorPicker({
        path: 'style.warningColor',
        name: 'Warning Color',
        category: ['Appearance'],
        defaultValue: 'rgb(255, 128, 0)',
      })
      .addColorPicker({
        path: 'style.dangerColor',
        name: 'Danger Color',
        category: ['Appearance'],
        defaultValue: 'rgb(196, 22, 42)',
      })
      .addColorPicker({
        path: 'style.noDataColor',
        name: 'No Data Color',
        category: ['Appearance'],
        defaultValue: 'rgb(123, 123, 138)',
      })

      //Icon Mapping
      .addCustomEditor({
        path: 'icons',
        id: 'iconMapping',
        editor: IconMapping,
        name: '',
        description:
          'This setting controls which images should be mapped to your directly monitored nodes. ' +
          'The node names are matched by the regex pattern provided in the "Target Name(Regex) column.',
        category: ['Node Type Mapping'],
        defaultValue: [
          {
            pattern: '[a-zA-Z]+[0-9]+',
            filename: 'default',
            node_title: 'Enlaces físicos del Emplazamiento',
            node_header: 'Destino,Activo,Emplazamiento,Tráfico(In/Out),Interfaces(Up/Down),-',
            links_title: 'Interfaces físicas del enlace',
            links_header: 'Origen,Slot/Puerto,Tipo,Destino,Slot/Puerto,Tipo,Estado',
          },
        ],
      })

      //External Icon Mapping
      .addCustomEditor({
        path: 'externalIcons',
        id: 'externalIconMapping',
        editor: IconMapping,
        name: '',
        description:
          'This setting controls which images should be mapped to the external nodes. ' +
          'The given type column is matched by the regex pattern provided in the "Target Name(Regex) column.',
        category: ['External Node Mapping'],
        defaultValue: [
          {
            pattern: '[a-zA-Z]+[0-9]+',
            filename: 'default',
            node_title: '-',
            node_header: '-',
            links_title: '-',
            links_header: '-',
          },
        ],
      })

      //Tracing Drilldown
      .addTextInput({
        path: 'drillDownLink_asset',
        name: 'Asset URL',
        category: ['Link to other features'],
        defaultValue: '/d/networking_datos_dispositivos?var-device=',
      })
      .addTextInput({
        path: 'drillDownLink_site',
        name: 'Site URL',
        category: ['Link to other features'],
        defaultValue: '/d/informacion_emp?var-emplazamiento=',
      })
  );
};
