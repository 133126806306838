// @ts-ignore
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { PanelProps, GraphSeriesValue, UrlQueryMap } from '@grafana/data';
import { Icon } from '@grafana/ui';
import { LineChartOptions, GenericLineData, GenericLineLabel, LegendLineLabel } from 'types';
import {
  BarChart,
  ComposedChart,
  Area,
  Bar,
  ReferenceArea,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  Brush,
  LabelList,
  Line,
} from 'recharts';
import { config, locationService, getLocationSrv, getTemplateSrv } from '@grafana/runtime';
import styled from 'styled-components';
import './css/LineChartPanel.css';

interface Props extends PanelProps<LineChartOptions> {}

export const LineChartPanel: React.FC<Props> = React.memo(({ options, data, width, height, replaceVariables, id }) => {
  const [initialCalculationsDone, setInitialCalculationsDone] = useState(false);
  const renderCount = useRef(0);
  const [error1, setError1 ] = useState('');
  const [error2, setError2 ] = useState('');
  const [error3, setError3 ] = useState('');
  const [error4, setError4 ] = useState('');

  const [auxButtonIcon1, setAuxButtonIcon1] = useState('');
  const [auxButtonVariable1, setAuxButtonVariable1] = useState('');
  const [auxButtonValue1, setAuxButtonValue1] = useState('');
  const [auxButtonIcon2, setAuxButtonIcon2] = useState('');
  const [auxButtonVariable2, setAuxButtonVariable2] = useState('');
  const [auxButtonValue2, setAuxButtonValue2] = useState('');
  const [auxButton1Enable, setAuxButton1Enable] = useState(false);
  const [auxButton2Enable, setAuxButton2Enable] = useState(false);
  const [chartTitle, setChartTitle] = useState('');
  const [chartSubTitle, setChartSubTitle] = useState('');
  const [chartIcon, setChartIcon] = useState('');
  const [absoluteThreshold, setAbsoluteThreshold] = useState('');
  const [syncID, setSyncID] = useState(String(id));
  const [clicEnable, setClicEnable] = useState(false);
  const [realTimeLink, setRealTimeLink] = useState('');
  const [analiticLink, setAnaliticLink] = useState('');
  var ifaceIdx = '';

  useEffect(() => {
    renderCount.current = renderCount.current + 1;
    if (!initialCalculationsDone) {
	  setError1(replaceVariables(options.error1));
      setError2(replaceVariables(options.error2));
      setError3(replaceVariables(options.error3));
      setError4(replaceVariables(options.error4));

      setAuxButtonIcon1(replaceVariables(options.toolbarAuxButtonIcon1));
      setAuxButtonVariable1(replaceVariables(options.toolbarAuxButtonVariable1));
      setAuxButtonValue1(replaceVariables(options.toolbarAuxButtonValue1));
      setAuxButtonIcon2(replaceVariables(options.toolbarAuxButtonIcon2));
      setAuxButtonVariable2(replaceVariables(options.toolbarAuxButtonVariable2));
      setAuxButtonValue2(replaceVariables(options.toolbarAuxButtonValue2));
      setAuxButton1Enable((auxButtonIcon1 !== '' && auxButtonVariable1 !== '' && auxButtonValue1 !== '') ? true : false);
      setAuxButton2Enable((auxButtonIcon2 !== '' && auxButtonVariable2 !== '' && auxButtonValue2 !== '') ? true : false);
      setChartTitle(options.showTitle ? replaceVariables(options.valueTitle) : '');
      setChartSubTitle(options.showTitle ? replaceVariables(options.chartSubTitle) : '');
      setChartIcon(options.showTitle ? replaceVariables(options.chartIcon) : '');
      setAbsoluteThreshold(Number(replaceVariables(options.absoluteThreshold)));
      setSyncID(options.syncId !== '' ? replaceVariables(options.syncId) : String(id));
	  setRealTimeLink(replaceVariables(options.realTimeButtonLink));
	  setAnaliticLink(replaceVariables(options.analiticButtonLink));
	  setInitialCalculationsDone(true);
	}
  }, [options, replaceVariables]);

  if (width < 150 || height < 75) {
    return (
	  <div className="lineChartErrorContainer" title={error4}>
	    <Icon name={'cloud-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.state === 'Error') {
    return (
	  <div className="lineChartErrorContainer" title={error1}>
	    <Icon name={'sync-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.series[0].length < 1) {
    return (
	  <div className="lineChartErrorContainer" title={error2}>
	    <Icon name={'image-slash'} size="xxl" />
	  </div>
	);
  }

  const wrapperWidth = useMemo(() => (width));
  const timeFrom = useMemo(() => (getTemplateSrv().replace('${__from}')));
  const timeTo = useMemo(() => (getTemplateSrv().replace('${__to}')));
  const timeInterval = Number(timeTo) - Number(timeFrom);
  var timeWindow = Number(timeInterval) / 3600000;
  var actualWin = 6;
  if (timeWindow <= 24) {
    actualWin = 1;
  } else if (timeWindow <= 168) {
    actualWin = 2;
  } else if (timeWindow <= 720) {
    actualWin = 3;
  } else if (timeWindow <= 2160) {
    actualWin = 4;
  } else if (timeWindow <= 4320) {
    actualWin = 5;
  }

  const [actualZoom, setActualZoom] = useState(actualWin);
  const [refAreaLeft, setRefAreaLeft] = useState('');
  const [refAreaRight, setRefAreaRight] = useState('');
  const [refAreaLeftLabel, setRefAreaLeftLabel] = useState(0);
  const [refAreaRightLabel, setRefAreaRightLabel] = useState(0);
  const [startZoom, setStartZoom] = useState(false);

  const stackElements: [] = [];
  const groupedChartLines: [] = [];
  const evolutionLine: [] = [];

  let chartIconSize = options.titleFontSize * 2 > 64 ? 64 : options.titleFontSize * 2;
  let axisLineWidth = options.axisLineWidth;

  const isDark = config.theme.isDark;
  const iconColor = isDark ? 'white' : 'black';
  const buttonColor = isDark ? 'black' : 'white';
  const buttonBorder = isDark ? '#2e2e2e' : '#e5e5e5';
  const boxBorder = isDark ? '#1b2733' : '#dce1e6';
  const tickFontColor = isDark ? '#D8DFD9' : '#23282E';
  const axisLineColor = isDark ? '#585A5E' : '#9DA5B8';
  const shadowColor = isDark ? '#5A6171' : '#646A73';
  var backgroundColor = options.backgroundColor;
  var borderColor = isDark ? '#44444C' : '#D8DFE9';
  if (backgroundColor === 'transparent') {
	borderColor = 'transparent';
  } else if (backgroundColor === 'text') {
	backgroundColor = isDark ? '#9DA5B8' : '#585A5E';	
  }

  const colors = isDark ? 
    ['#F17171','#71E200','#A2ADB8','#7100FF','#FFA071','#A20471','#FF71B7','#7EA9ff','#FF0071','#B380FF'] :
	['#D64545','#45C5B0','#7D8995','#45B0E5','#E57A45','#7DB545','#E54594','#5485E5','#E58045','#9065E5'];
  const getColor = (color: number) => {
    let colorNumber = color < 0 ? 0 : color;
    if (colorNumber > colors.length) {
      colorNumber = ((color - 1) % colors.length);
    }
    return colors[colorNumber];
  }

  var showDot = false;
  var showActiveDot = false;
  if (options.orientation === 'vertical') {
	if (options.scaleTypeVertical !== 'log' && options.scaleTypeVertical !== 'ordinal') {
	  if (options.showDots === true) {
		showDot = true;
	  }
	  showActiveDot = true;
	}
  } else {
	if (options.scaleType !== 'log' && options.scaleType !== 'ordinal') {
	  if (options.showDots === true) {
		showDot = true;
	  }
	  showActiveDot = true;
	}
  }
  const rightMargin = !options.timeSeriesChart && options.showAxisY ? 25 : 5;
  const topMargin = !options.timeSeriesChart && options.showAxisX ? 25 : 10;
  var chartMargin = { top: 10, right: 5, left: 5, bottom: 5 };
  if (!options.timeSeriesChart && options.orientation === 'horizontal') {
	chartMargin = { top: topMargin, right: 5, left: 5, bottom: 5};
  } else if (!options.timeSeriesChart) {
	chartMargin = { top: 5, right: rightMargin, left: 5, bottom: 5};
  }

  const useNormalTooltip = (syncID !== String(id) || (wrapperWidth > 350 && height > 150)) ? true : false;
  const LineChartToolboxContainer = styled.div`
    display: inline-flex;
    width: 100%;
  `;

  const ButtonContainer = styled.div`
    position: absolute;
    top: 5px;
    width: 32px;
	border-left: 1px solid ${boxBorder};
  `;

  const ChartContainer = styled.div`
    display: inline-flex;
	position: absolute;
	width: -webkit-fill-available;
    height: -webkit-fill-available;
  `;

  const ButtonNormal = styled.button`
    display: inline-flex;
    height: 32px;
    width: 28px;
	margin-left: 2px;
    background-color: transparent;
    opacity: 0.75;
    justify-content: center;
	align-items: center;
    color: ${iconColor};
    border: 1px solid transparent;
    border-radius: 10px;

    &:hover {
      opacity: 1;
	  background-image: linear-gradient(to bottom right, #e5e5e529, #b7b7b76e);
	  border: 1px solid ${buttonBorder};
    }
  `;

  const ButtonText = styled.div`
    width: 28px;
  `;

  const renderColorfulLegendText = (value: string) => {
    if (value === 'Zoom') {
	  return null;
	}
	const textColor = isDark ? '#ffffff': '#000000';
    return <span style={{color: textColor, cursor: 'pointer' }}>{value}</span>;
  };

  var showTooltip = false;
  var tooltipVisible = null;
  var tooltipTimeout;
  var showSecondLine = options.value2Field !== undefined && options.value2Field !== null && options.value2Field !== '' ? 
    true : false;
  var showThirthLine = options.value3Field !== undefined && options.value3Field !== null && options.value3Field !== '' ?
    true : false;

  var axisXEndIndex = 0;
  var axisXMaxIndex = 0;
  var axisXStartIndex = 0;
  var axisLYEndIndex = options.maxValue;
  var axisLYStartIndex = options.minValue;
  var axisRYEndIndex = options.maxValue;
  var axisRYStartIndex = options.minValue;

  var toolBoxWidth = options.showTitle ? wrapperWidth * 0.5 : 0;
  if ((options.showToolbar || options.showAuxToolbar) && !options.showTitle) {
	toolBoxWidth = 32;
  } else if (options.showTitle && chartTitle !== '' && wrapperWidth >= 250) {
	toolBoxWidth = (wrapperWidth * 0.5 < 125) ? 125 : wrapperWidth * 0.5;
  }
  if (options.showTitle && chartTitle !== '' && toolBoxWidth > 300) {
    toolBoxWidth = 300;
  }

  const generateChart = useMemo(() => {
    const chartLines: GenericLineData[] = [];
    if (!initialCalculationsDone) {
	  return chartLines;
	}
    renderCount.current = renderCount.current + 1;
    const d = Date.now();
    data.series.forEach((series) => {
	  const chart: GraphSeriesValue[] = series.fields[0].values.toArray();
	  for (let i = 0; i < chart.length; i++) {
	    var chartLine: GenericLineData = {
		  id: i + 1,
		  name: series.fields.find((field) => field.name === options.serieField)?.values.get(i) || '',
		  date: new Date(d).toUTCString(),
		  dateLocale: new Date(d).toLocaleString(),
		  value: 0,
		  value2: 0,
		  value3: 0,
		  auxarea: 0,
		  auxline: 0,
		  stackId: 'mainLine',
		  label: series.fields.find((field) => field.name === options.labelField)?.values.get(i) || '',
		  label2: series.fields.find((field) => field.name === options.label2Field)?.values.get(i) || '',
		  label3: series.fields.find((field) => field.name === options.label3Field)?.values.get(i) || '',
		  color: options.lineColor,
		  info: '',
		  metric: 0,
		  threshold: 0,
		  onfire: false,
	    };
	    if (i === 0) {
		  ifaceIdx = chartLine.name;
		}
		const stackID = series.fields.find((field) => field.name === options.stackField)?.values.get(i);
	    if (stackID !== undefined && stackID !== null && stackID !== '') {
		  chartLine.stackId = String(stackID);
	    } 
	    if (options.timeSeriesChart && options.dateField !== undefined && options.dateField !== '') {
		  let unixDate = series.fields.find((field) => field.name === options.dateField)?.values.get(i);
		  chartLine.date = new Date(unixDate).toUTCString();
		  chartLine.dateLocale = new Date(unixDate).toLocaleString();
		  if (actualZoom < 3) {
		    chartLine.name = new Date(unixDate).toLocaleTimeString(options.timeZone);
		  } else {
		    chartLine.name = new Date(unixDate).toLocaleDateString(options.timeZone).replace(/\//g, '-');
		  }
	    }
	    if (options.valueField !== undefined && options.valueField !== '') {
		  const value = series.fields.find((field) => field.name === options.valueField)?.values.get(i);
		  chartLine.value = isNaN(value) ? 0 : Number(value);
	    }
	    if (options.valueField !== undefined && options.valueField !== '') {
		  const value = series.fields.find((field) => field.name === options.value2Field)?.values.get(i);
		  chartLine.value2 = isNaN(value) ? 0 : Number(value);
	    }
	    if (options.valueField !== undefined && options.valueField !== '') {
		  const value = series.fields.find((field) => field.name === options.value3Field)?.values.get(i);
		  chartLine.value3 = isNaN(value) ? 0 : Number(value);
	    }
	    const lineColor = series.fields.find((field) => field.name === options.colorField)?.values.get(i);
	    if (lineColor !== undefined && lineColor !== null && lineColor !== '') {
		  chartLine.color = lineColor;
	    } else if (chartLine.color === '') {
	      chartLine.color = getColor(chartLine.id + id, isDark);
	    }
	    if (options.showAreaOnChart && options.areaField !== undefined && options.areaField !== '') {
		  const auxarea = series.fields.find((field) => field.name === options.areaField)?.values.get(i);
		  chartLine.auxarea = isNaN(auxarea) ? 0 : Number(auxarea);
	    }
	    if (options.showLineOnChart && options.lineField !== undefined && options.lineField !== '') {
		  const auxline = series.fields.find((field) => field.name === options.lineField)?.values.get(i);
		  chartLine.auxline = isNaN(auxline) ? 0 : Number(auxline);
	    }
	    if (options.useInfoField && options.infoField !== undefined && options.infoField !== '') {
		  chartLine.info = series.fields.find((field) => field.name === options.infoField)?.values.get(i);
	    }
	    if (options.useMetricField) {
		  if (options.metricField !== undefined && options.metricField !== '') {
		    let metric = series.fields.find((field) => field.name === options.metricField)?.values.get(i);
		    chartLine.metric = isNaN(metric) ? 0 : Number(metric);
		  }
		  if (options.metricThresholdField !== undefined && options.metricThresholdField !== '') {
		    let relativeThreshold = series.fields
			  .find((field) => field.name === options.metricThresholdField)
			  ?.values.get(i);
		    chartLine.threshold = isNaN(relativeThreshold) ? 1 : Number(relativeThreshold) / 100;
		  }
	    }
	    if (chartLine.metric > 0 && chartLine.threshold > 0) {
		  if (
		    chartLine.value > chartLine.metric * (1 + chartLine.threshold) ||
		    chartLine.value2 > chartLine.metric * (1 + chartLine.threshold) ||
		    chartLine.value3 > chartLine.metric * (1 + chartLine.threshold)
		  ) {
		    chartLine.onfire = true;
		  }
	    }
	    if (
		  absoluteThreshold > 0 &&
		  (chartLine.value > absoluteThreshold ||
		    chartLine.value2 > absoluteThreshold ||
		    chartLine.value3 > absoluteThreshold)
	    ) {
		  chartLine.onfire = true;
	    }
	    if (options.invertThreshold) {
		  chartLine.onfire = !chartLine.onfire;
	    }
	    chartLines.push(chartLine);
	  }
    });
    return chartLines;
  }, [options, data, initialCalculationsDone]);

  if (generateChart.length > 0) {
    var idx = 0;
    var idxStack = 1;
    generateChart.forEach(chartLine => {
	  var existingItem = null;
	  if (options.timeSeriesChart) {
	    existingItem = groupedChartLines !== null ? groupedChartLines.find(item => item.dateLocale === chartLine.dateLocale) : null;
	  } else {
	    existingItem = groupedChartLines !== null ? groupedChartLines.find(item => item.name === chartLine.name) : null;
	  }
      if (existingItem) {
        existingItem[`color_${chartLine.stackId}`] = chartLine.color;
        existingItem[`${chartLine.stackId}`] = chartLine.value;
	    existingItem[`metric_${chartLine.stackId}`] = chartLine.metric;
	    existingItem[`onfire_${chartLine.stackId}`] = chartLine.onfire;
	    existingItem[`label_${chartLine.stackId}`] = chartLine.label;
        existingItem.maxmetric = existingItem.maxmetric < chartLine.metric ? chartLine.metric : existingItem.maxmetric;
	    if (options.stackValues) {
	      existingItem.totalvalue = existingItem.totalvalue + chartLine.value;
	    } else if (chartLine.value > existingItem.totalvalue) {
	      existingItem.totalvalue = chartLine.value;
	    }
	    if (options.stackValues && options.accumulate) {
	      existingItem.auxarea = existingItem.auxarea + chartLine.auxarea;
	      existingItem.auxline = existingItem.auxline + chartLine.auxline;
	    }
      } else {
        const newPoint = {
		  id: idx,
		  date: chartLine.date,
		  value: chartLine.value,
		}
		const newItem = {
		  id: idx,
		  name: chartLine.name,
		  date: chartLine.date,
		  dateLocale: chartLine.dateLocale,
		  auxarea: chartLine.auxarea,
		  auxline: chartLine.auxline,
		  info: chartLine.info,
		  maxmetric: chartLine.metric,
		  maxvalue: chartLine.metric,
		  slope: 0,
		  threshold: chartLine.threshold,
		  totalvalue: options.stackValues ? 
		    (chartLine.value + chartLine.value2 + chartLine.value3) : 
		    Math.max(chartLine.value, chartLine.value2, chartLine.value3),
		  [`color_${chartLine.stackId}`]: chartLine.color,
          [`${chartLine.stackId}`]: chartLine.value,
          [`metric_${chartLine.stackId}`]: chartLine.metric,
          [`onfire_${chartLine.stackId}`]: chartLine.onfire,
          [`label_${chartLine.stackId}`]: chartLine.label,
        };
	    if (showSecondLine) {
		  newItem[`color_2ndLine`] = options.line2Color;
          newItem[`2ndLine`] = chartLine.value2;
          newItem[`metric_2ndLine`] = chartLine.metric;
          newItem[`onfire_2ndLine`] = chartLine.onfire;
          newItem[`label_2ndLine`] = chartLine.label2;
		  const existingStackId = stackElements !== null ? stackElements.find(item => item.stackId === '2ndLine') : null;
		  if (!existingStackId) {
		    const newStackId = {
		      id: idxStack,
		      stackId: '2ndLine',
			  stackLabel: options.value2Title,
		      color: options.line2Color,
	          lineWidth: options.hideFillLine && options.fillLine2 ? 0 : options.lineWidth,
			  lineFilled: options.fillLine2,
		    }
		    idxStack = idxStack + 1;
		    stackElements.push(newStackId);
		  }
	    }
	    if (showThirthLine) {
		  newItem[`color_3rdLine`] = options.line3Color;
          newItem[`3rdLine`] = chartLine.value3;
          newItem[`metric_3rdLine`] = chartLine.metric;
          newItem[`onfire_3rdLine`] = chartLine.onfire;
          newItem[`label_3rdLine`] = chartLine.label3;
		  const existingStackId = stackElements !== null ? stackElements.find(item => item.stackId === '3rdLine') : null;
		  if (!existingStackId) {
		    const newStackId = {
		      id: idxStack,
		      stackId: '3rdLine',
			  stackLabel: options.value3Title,
		      color: options.line3Color,
	          lineWidth: options.hideFillLine && options.fillLine3 ? 0 : options.lineWidth,
			  lineFilled: options.fillLine3,
		    };
		    idxStack = idxStack + 1;
		    stackElements.push(newStackId);
		  }
	    }
	    idx = idx + 1;
	    axisXMaxIndex = idx;
	    groupedChartLines.push(newItem);
		evolutionLine.push(newPoint);
      }
	  const existingStackId = stackElements !== null ? stackElements.find(item => item.stackId === chartLine.stackId) : null;
	  if (!existingStackId) {
	    const newStackId = {
	  	  id: idxStack,
		  stackId: chartLine.stackId,
		  stackLabel: options.valueTitle,
		  color: chartLine.color,
	      lineWidth: chartLine.stackId === 'mainLine' && options.hideFillLine && options.fillLine ? 0 : options.lineWidth,
		  lineFilled: chartLine.stackId === 'mainLine' ? options.fillLine : false,
	    };
	    idxStack = idxStack + 1;
	    stackElements.push(newStackId); 
	  }
    });

    let minValueL = 0;
    let maxValueL = 0;
    let minValueR = 0;
    let maxValueR = 0;
    if (options.maxValue === 0 || options.maxValue === undefined || options.maxValue === null) {
	  groupedChartLines.forEach(groupedChartLine => {
	    const minLeft = groupedChartLine.totalvalue > groupedChartLine.maxmetric ? groupedChartLine.maxmetric : groupedChartLine.totalvalue;
	    const maxLeft = groupedChartLine.totalvalue > groupedChartLine.maxmetric ? groupedChartLine.totalvalue : groupedChartLine.maxmetric;
	    if (maxLeft > maxValueL) {
		  maxValueL = maxLeft;
	    }
	    if (minLeft < minValueL) {
		  minValueL = minLeft;
	    }
	    const minRight = groupedChartLine.auxline > groupedChartLine.auxarea ? groupedChartLine.auxarea : groupedChartLine.auxline;
	    const maxRight = groupedChartLine.auxline > groupedChartLine.auxarea ? groupedChartLine.auxline : groupedChartLine.auxarea;
	    if (maxRight > maxValueR) {
	      maxValueR = maxRight;
	    }
	    if (minRight < minValueR) {
	      minValueR = minRight;
	    }
	  });
	  axisLYEndIndex = options.showLabels && options.showTotals ? maxValueL * 1.2 : maxValueL * 1.1;
	  axisRYEndIndex = options.showLabels && options.showTotals ? maxValueR * 1.2 : maxValueR * 1.1;
	  if (options.timeSeriesChart) {
	    groupedChartLines.forEach(groupedChartLine => {
	      groupedChartLine.maxvalue = axisLYEndIndex > axisRYEndIndex ? axisLYEndIndex : axisRYEndIndex;
	    });
	  }
    }

    if (options.minValue === 0 || options.minValue === undefined || options.minValue === null) {
      axisLYStartIndex = minValueL >= 0 ? 0 : minValueL * 1.1;
	  axisRYStartIndex = minValueR >= 0 ? 0 : minValueR * 1.1;
    }
  
    if (axisLYEndIndex === 0) {
      axisLYEndIndex = 1;
    }
    if (axisRYEndIndex === 0) {
      axisRYEndIndex = 1;
    }
    axisLYEndIndex = axisLYEndIndex > 1 ? Math.round(axisLYEndIndex) : Math.round(axisLYEndIndex * 100) / 100;
    axisRYEndIndex = axisRYEndIndex > 1 ? Math.round(axisRYEndIndex) : Math.round(axisRYEndIndex * 100) / 100;

    if (options.timeSeriesChart && options.endIndex > 0) {
	  axisXEndIndex = options.endIndex;
    } else {
	  axisXEndIndex = axisXMaxIndex;
    }
    if (options.timeSeriesChart && options.startIndex > 0) {
	  axisXStartIndex = axisXEndIndex - options.startIndex;
    }
  }

  let delta = '0';
  if (options.showTendence) {
    const { initialSlope, finalSlope } = getSlope(evolutionLine);
    const deltaValue = (finalSlope - initialSlope) / evolutionLine.length;
	delta  = ((finalSlope - initialSlope) / initialSlope * 100).toFixed(2);
    for (let i = 0; i < groupedChartLines.length; i++) {
      groupedChartLines[i].slope = initialSlope + (deltaValue * i);
    }
  }
  
  const [lineProps, setLineProps] = useState({ hover: null, selected: null });
  const selectLine = (e) => {
	setLineProps({
      ...lineProps,
      hover: null,
      selected: lineProps.selected !== e.dataKey ? e.dataKey : null
    });
  };

  const areaFactory = (timeSeries: boolean) => {
    if (!stackElements) {
      return null;
    }
    const backgroundColor = isDark ? 'white' : 'black';
    const orientation = options.orientation;
    const shadowColor = isDark ? '#5A6171' : '#646A73';

    if (stackElements && lineProps) {
	  return stackElements.map((entry, i) => (
	    <Area
		  yAxisId={'mainline'}
		  dataKey={stackElements[i].stackId}
		  type={options.lineType}
		  name={stackElements[i].stackLabel}
		  unit={options.metricUnit}
		  dot={options.showDots ? {
		    stroke: stackElements[i].color, 
		    strokeWidth: stackElements[i].lineWidth,
		    fill: backgroundColor
		  } : false}
		  activeDot={{ stroke: isDark ? '#F74545' : '#FB3333', strokeWidth: 1, r: 3 }}
		  hide={lineProps.selected && lineProps.selected !== `${stackElements[i].stackId}`}
		  style={!stackElements[i].lineFilled ? { filter: `drop-shadow(2px 2px 5px ${shadowColor})` } : null}
		  fill={stackElements[i].lineFilled ? 'url(#line_' + orientation + '_' + stackElements[i].stackId + ')' : 'transparent'}
		  stroke={stackElements[i].lineWidth > 0 ? stackElements[i].color : 'transparent'}
		  strokeWidth={stackElements[i].lineWidth > 0 ? stackElements[i].lineWidth : 1}
		  stackId={options.stackValues ? id : stackElements[i].id}
		  isAnimationActive={renderCount.current > 1 ? false : options.isAnimationActive}
	    >
	    </Area>
	  ));
    }
  };

  const NormalTooltip = ({ payload, label }: any) => {
	const toolTipLabel = CustomTooltip(label, showTooltip, payload, options, clicEnable);
	return toolTipLabel;
  };

  const showTooltipChart = (e, data) => {
	if (e && data) {
	  let x = data.pageX;
	  if (data.view.innerWidth !== undefined) {
	    x = data.pageX > data.view.innerWidth - 160 ? data.view.innerWidth - 160 : data.pageX;
	  }
      const y = data.pageY;
	  if (tooltipVisible && e.activeLabel === tooltipVisible) {
	    $('#panelTooltip').css({
          'left': x + 10 + 'px',
          'top': y + 'px',
          'visibility': 'visible',
        });
	  } else if (e && e.activePayload) {
	    const toolTipLabel = renderToStaticMarkup(CustomTooltip(e.activeLabel, showTooltip, e.activePayload, options, clicEnable));
        $('#panelTooltip').html(toolTipLabel);
        $('#panelTooltip').addClass('lineChartTooltip');
	    $('#panelTooltip').css({
          'left': x + 10 + 'px',
          'top': y + 'px',
          'visibility': 'visible',
        });
	    if (e.activeLabel) {
	      tooltipVisible = e.activeLabel;
	    }
	  }
	  clearTimeout(tooltipTimeout);
	  tooltipTimeout = setTimeout(() => {
        hideTooltipChart();
      }, 2000);
	} else{
	  tooltipVisible = null;
	}
  };

  const hideTooltipChart = (e) => {
    clearTimeout(tooltipTimeout);
	$('#panelTooltip').html('');
    $('#panelTooltip').removeClass('lineChartTooltip');
    $('#panelTooltip').css({
      'visibility': 'hidden' 
    });
	tooltipVisible = null;
  };

  var titleIn = 'Zoom In';
  var titleOut = 'Zoom Out';
  if (actualZoom === 1) {
	titleIn = 'No es posible reducir ventana de tiempo';
	titleOut = 'Ampliar ventana de tiempo a ultimas 24hs';
  } else if (actualZoom === 2) {
	titleIn = 'Reducir ventana de tiempo a ultimas 3hs';
	titleOut = 'Ampliar ventana de tiempo a ultima semana';
  } else if (actualZoom === 3) {
	titleIn = 'Reducir ventana de tiempo a ultimas 24hs';
	titleOut = 'Ampliar ventana de tiempo a ultimo mes';
  } else if (actualZoom === 4) {
	titleIn = 'Reducir ventana de tiempo a ultima semana';
	titleOut = 'Ampliar ventana de tiempo a ultimo trimetre';
  } else if (actualZoom === 5) {
	titleIn = 'Reducir ventana de tiempo a ultimo mes';
	titleOut = 'Ampliar ventana de tiempo a ultimo semestre';
  } else if (actualZoom === 6) {
	titleIn = 'Reducir ventana de tiempo a ultimo trimetre';
	titleOut = 'Ampliar ventana de tiempo a ultimo año';
  } else if (actualZoom === 7) {
	titleIn = 'Reducir ventana de tiempo a ultimo semestre';
	titleOut = 'No es posible ampliar ventana de tiempo';
  }

  if (!options.timeSeriesChart && options.orientation === 'vertical') {
	return (
	  <LineChartToolboxContainer id={id}>
		{options.showAuxToolbar && (auxButton1Enable || auxButton2Enable) && !options.showTitle && (
		  <ButtonContainer>
			{auxButton1Enable && (
			  <ButtonNormal
				title={options.toolbarAuxButtonTitle1}
				onClick={() => {
				  handleAuxClick(auxButtonVariable1, auxButtonValue1);
				}}
			  >
				<ButtonText><Icon name={auxButtonIcon1} size="lg" /></ButtonText>
			  </ButtonNormal>
			)}
			{auxButton2Enable && (
			  <ButtonNormal
				title={options.toolbarAuxButtonTitle2}
				onClick={() => {
				  handleAuxClick(auxButtonVariable2, auxButtonValue2);
				}}
			  >
				<ButtonText><Icon name={auxButtonIcon2} size="lg" /></ButtonText>
			  </ButtonNormal>
			)}
		  </ButtonContainer>
		)}
		{options.showTitle && chartTitle !== '' && wrapperWidth >= 250 && (
		  <button
            style={{
              fontSize: options.titleFontSize,
			  width: toolBoxWidth,
			  height: height - 10,
            }}
            className={'lineChart_button'}
			onClick={() => {
			  handleClick(url_link, '_self');
			}}
            title={chartTitle}
          >
		    <div 
			  className={'lineChartIndicator'}
			  style={ wrapperWidth > 480 ? 
			    {
			      width: toolBoxWidth,
			      height: height - 10,
			    } : {
			      height: height - 10,
			    }
			  }
		    >
			  {chartIcon !== '' && (
			    <div 
				  className={'icon_lineChart'}
			      style={{
				    color: options.iconColor,
					width: chartIconSize + 20,
			      }}
				>
				  <Icon name={chartIcon} size={chartIconSize + 10} />
			    </div>
			  )}
			  <div className={'lineChart_label'}>
			    <b>{chartTitle}</b>
			    <div
			      className={'lineChart_sublabel'}
			      style={{
				    fontColor: iconColor,
					fontSize: options.fontSize,
			      }}
			    >
			      {chartSubTitle}
			    </div>
			  </div>
		    </div>
          </button>
		)}
		<ChartContainer style={{ left: toolBoxWidth + 5, bottom: 0 }}>
		  <ResponsiveContainer width={wrapperWidth - toolBoxWidth - 5} height={height - 10}>
		    <ComposedChart
			  style={{ width: '100%', height: '100%' }}
			  width={wrapperWidth - toolBoxWidth}
			  height={height - 10}
			  data={groupedChartLines}
			  layout={'vertical'}
			  syncId={syncID}
			  margin={chartMargin}
			  onClick={(e) => {
			    if (clicEnable) {
				  handleClick(url_link, '_self');
			    }
			  }}
			  onMouseMove={(e, data) => {
				if (options.showTooltip && !useNormalTooltip) {
				  showTooltip = true;
				  showTooltipChart(e, data);
				}
			  }}
              onMouseLeave={(e) => {
			    if (options.showTooltip && !useNormalTooltip) {
				  hideTooltipChart(e);
				  showTooltip = false;
				}
			  }}
		    >
			  <defs>
		        {stackElements.map(stack => (
				  <linearGradient id={'line__vertical_' + stack.stackId} x1="1" y1="0" x2="0" y2="0">
				    <stop offset="0%" stopColor={stack.color} stopOpacity={options.fillOpacityStart} />
				    <stop offset="95%" stopColor={stack.color} stopOpacity={options.fillOpacityEnd} />
				  </linearGradient>
			    ))}
				<filter id={'LineShadow_vertical'}>
				  <feOffset result="offOut" in="SourceAlpha" dx={0} dy={0} />
				  <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2 2" />
				  <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
				</filter>
			    <linearGradient id={'auxAreaColor_vertical_' + id} x1="1" y1="0" x2="0" y2="0">
				  <stop offset="0%" stopColor={options.auxAreaColor} stopOpacity={options.fillOpacityStart} />
				  <stop offset="95%" stopColor={options.auxAreaColor} stopOpacity={options.fillOpacityEnd} />
			    </linearGradient>
			  </defs>
			  {stackElements.map(stack => (
			    <XAxis
				  xAxisId={'mainline'}
				  dataKey={stack.stackId}
				  type="number"
				  allowDecimals={axisLYEndIndex > 1 ? false : true}
				  scale={options.scaleTypeVertical}
				  tick={{ fontSize: options.fontSize, fill: tickFontColor }}
				  interval="preserveEnd"
				  tickMargin={5}
				  hide={!options.showAxisX}
				  domain={options.scaleTypeVertical === 'log' ? ['auto', 'auto'] : [axisLYStartIndex, axisLYEndIndex]}
				  axisLine={{ stroke: axisLineColor, strokeWidth: axisLineWidth }}
				  tickLine={{ stroke: 'orange', strokeWidth: 1, transform: 'translate(-2, 0)' }}
			    />
			  ))}
			  <YAxis
				dataKey="name"
				type="category"
				scale={'band'}
				tick={{ fontSize: options.fontSize, fill: tickFontColor, transform: 'translate(-2, 0)' }}
				interval={stackElements.length > 1 ? 'preserveStartEnd' : 0}
				tickMargin={5}
				tickSize={5}
				hide={!options.showAxisY}
				axisLine={{ stroke: axisLineColor, strokeWidth: axisLineWidth }}
				tickLine={{ stroke: 'orange', strokeWidth: 1, transform: 'translate(-2, 0)' }}
			  />
			  {areaFactory(false)}
			  {options.showMetricOnChart && stackElements.length === 1 && stackElements.map(stack => (
				<Line
				  yAxisId={'mainline'}
				  type={options.lineType}
				  name={options.metricTitle}
				  unit={options.metricUnit}
				  dataKey={'metric_' + stack.stackId}
				  stroke={options.metricLineColor}
				  strokeWidth={options.lineWidth}
				  hide={lineProps[options.metricTitle] === true}
				  strokeDasharray="5 5"
				  activeDot={showActiveDot === true ? true : false}
				  dot={showDot ? {
				    stroke: options.metricLineColor, 
					strokeWidth: options.lineWidth,
					fill: backgroundColor
				  } : false}
				  isAnimationActive={options.isAnimationActive}
			    />
			  ))}
			  {options.useCartesianGrid && <CartesianGrid strokeDasharray="3 3" vertical={false} />}
			  {options.showTooltip && useNormalTooltip && (
			    <Tooltip 
				  content={<NormalTooltip />}
				  cursor={{ stroke: isDark ? '#F4F9FF' : '#141618', strokeWidth: 1 }}
				  isAnimationActive={false}
				/>
			  )}
			  {!options.showTitle && options.showLegend && (
			    <Legend
				  wrapperStyle={{ fontSize: options.fontSize, left: 0, top: 5 }}
				  formatter={renderColorfulLegendText}
				  iconSize={options.fontSize + 5}
				  iconType={'circle'}
				  verticalAlign={'top'}
				  margin={{ top: 5, left: 0, right: 0, bottom: 10 }}
				  onClick={selectLine}
			    />
			  )}
		    </ComposedChart>
		  </ResponsiveContainer>
		</ChartContainer>
	  </LineChartToolboxContainer>
	);
  } else if (!options.timeSeriesChart && options.orientation === 'horizontal') {
	return (
	  <LineChartToolboxContainer id={id}>
		{options.showAuxToolbar && (auxButton1Enable || auxButton2Enable) && !options.showTitle && (
		  <ButtonContainer>
			{auxButton1Enable && (
			  <ButtonNormal
				title={options.toolbarAuxButtonTitle1}
				onClick={() => {
				  handleAuxClick(auxButtonVariable1, auxButtonValue1);
				}}
			  >
				<ButtonText><Icon name={auxButtonIcon1} size="lg" /></ButtonText>
			  </ButtonNormal>
			)}
			{auxButton2Enable && (
			  <ButtonNormal
				title={options.toolbarAuxButtonTitle2}
				onClick={() => {
				  handleAuxClick(auxButtonVariable2, auxButtonValue2);
				}}
			  >
				<ButtonText><Icon name={auxButtonIcon2} size="lg" /></ButtonText>
			  </ButtonNormal>
			)}
		  </ButtonContainer>
		)}
		{options.showTitle && chartTitle !== '' && wrapperWidth >= 250 && (
		  <button
            style={{
              fontSize: options.titleFontSize,
			  width: toolBoxWidth,
			  height: height - 10,
            }}
            className={'lineChart_button'}
			onClick={() => {
			  handleClick(url_link, '_self');
			}}
            title={chartTitle}
          >
		    <div 
			  className={'lineChartIndicator'}
			  style={{
			    width: toolBoxWidth,
			    height: height - 10,
			  }}
		    >
			  {chartIcon !== '' && (
			    <div 
				  className={'icon_lineChart'}
			      style={{
				    color: options.iconColor,
					width: chartIconSize + 20,
			      }}
				>
				  <Icon name={chartIcon} size={chartIconSize + 10} />
			    </div>
			  )}
			  <div className={'lineChart_label'}>
			    <b>{chartTitle}</b>
			    <div
			      className={'lineChart_sublabel'}
			      style={{
				    fontColor: iconColor,
					fontSize: options.fontSize,
			      }}
			    >
			      {chartSubTitle}
			    </div>
			  </div>
		    </div>
          </button>
		)}
		<ChartContainer style={{ left: toolBoxWidth + 5, bottom: 0 }}>
		  <ResponsiveContainer width={wrapperWidth - toolBoxWidth - 5} height={height}>
		    <ComposedChart
			  style={{ width: '100%', height: '100%' }}
			  width={wrapperWidth - toolBoxWidth}
			  height={height}
			  data={groupedChartLines}
			  layout={'horizontal'}
			  syncId={syncID}
			  margin={chartMargin}
			  onClick={(e) => {
			    if (clicEnable) {
				  handleClick(url_link, '_self');
			    }
			  }}
			  onMouseMove={(e, data) => {
				if (options.showTooltip && !useNormalTooltip) {
				  showTooltip = true;
				  showTooltipChart(e, data);
				}
			  }}
              onMouseLeave={(e) => {
			    if (options.showTooltip && !useNormalTooltip) {
				  hideTooltipChart(e);
				  showTooltip = false;
				}
			  }}
		    >
			  <defs>
		        {stackElements.map(stack => (
				  <linearGradient id={'line_horizontal_' + stack.stackId} x1="0" y1="0" x2="0" y2="1">
				    <stop offset="0%" stopColor={stack.color} stopOpacity={options.fillOpacityStart} />
				    <stop offset="95%" stopColor={stack.color} stopOpacity={options.fillOpacityEnd} />
				  </linearGradient>
			    ))}
			    <linearGradient id={'auxAreaColor_horizontal_' + id} x1="0" y1="0" x2="0" y2="1">
				  <stop offset="0%" stopColor={options.auxAreaColor} stopOpacity={options.fillOpacityStart} />
				  <stop offset="95%" stopColor={options.auxAreaColor} stopOpacity={options.fillOpacityEnd} />
			    </linearGradient>
			  </defs>
			  <XAxis
				dataKey="name"
				type="category"
				scale={'point'}
				tick={{ fontSize: options.fontSize, fill: tickFontColor, transform: 'translate(0, 2)' }}
				interval="preserveStartEnd"
				tickMargin={5}
				tickSize={5}
				hide={!options.showAxisX}
				axisLine={{ stroke: axisLineColor, strokeWidth: axisLineWidth }}
				tickLine={{ stroke: 'orange', strokeWidth: 1, transform: 'translate(0, 2)' }}
			  />
			  {stackElements.map(stack => (
			    <YAxis
				  yAxisId={'mainline'}
				  dataKey={stack.stackId}
				  type="number"
				  allowDecimals={axisLYEndIndex > 1 ? false : true}
				  scale={options.scaleType}
				  tick={{ fontSize: options.fontSize, fill: tickFontColor }}
				  interval="preserveEnd"
				  tickMargin={5}
				  hide={!options.showAxisY}
				  domain={options.scaleType === 'log' ? ['auto', 'auto'] : [axisLYStartIndex, axisLYEndIndex]}
				  axisLine={{ stroke: axisLineColor, strokeWidth: axisLineWidth }}
				  tickLine={{ stroke: 'orange', strokeWidth: 1, transform: 'translate(0, 2)' }}
			    />
			  ))}
			  {areaFactory(false)}
			  {options.showMetricOnChart && stackElements.length === 1 && stackElements.map(stack => (
				<Line
				  yAxisId={'mainline'}
				  type={options.lineType}
				  name={options.metricTitle}
				  unit={options.metricUnit}
				  dataKey={'metric_' + stack.stackId}
				  style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
				  stroke={options.metricLineColor}
				  strokeWidth={options.lineWidth}
				  hide={lineProps[options.metricTitle] === true}
				  strokeDasharray="5 5"
				  activeDot={showActiveDot === true ? true : false}
				  dot={showDot ? {
				    stroke: options.metricLineColor, 
					strokeWidth: options.lineWidth,
					fill: backgroundColor
				  } : false}
				  isAnimationActive={renderCount.current > 1 ? false : options.isAnimationActive}
			    />
			  ))}
			  {options.useCartesianGrid && <CartesianGrid strokeDasharray="3 3" vertical={false} />}
			  {options.showTooltip && useNormalTooltip && (
			    <Tooltip 
				  content={<NormalTooltip />}
				  cursor={{ stroke: isDark ? '#F4F9FF' : '#141618', strokeWidth: 1 }}
				  isAnimationActive={false}
				/>
			  )}
			  {!options.showTitle && options.showLegend && (
			    <Legend
				  wrapperStyle={{ fontSize: options.fontSize, left: 0, top: 5 }}
				  formatter={renderColorfulLegendText}
				  iconSize={options.fontSize + 5}
				  iconType={'circle'}
				  verticalAlign={'top'}
				  margin={{ top: 5, left: 0, right: 0, bottom: 10 }}
				  onClick={selectLine}
			    />
			  )}
		    </ComposedChart>
		  </ResponsiveContainer>
		</ChartContainer>
	  </LineChartToolboxContainer>
	);
  } else if (options.timeSeriesChart) {
	return (
	  <LineChartToolboxContainer id={id}>
		{options.showToolbar && (
		  <ButtonContainer>
			{options.showRealTimeButton && (
			  <ButtonNormal
				title={options.realTimeTitle}
				onClick={() => {
				  handleClick(realTimeLink + ifaceIdx, '_blank');
				}}
			  >
				<ButtonText><Icon name={'waveform-circle-fill'} size="xl" /></ButtonText>
			  </ButtonNormal>
			)}
			{options.showAnaliticButton && (
			  <ButtonNormal
				title={options.analiticButtonTitle}
				onClick={() => {
				  handleClick(analiticLink, '_blank');
				}}
			  >
				<ButtonText><Icon name={'brain'} size="lg" /></ButtonText>
			  </ButtonNormal>
			)}
			{options.showTimeZoomButtons && (
			  <ButtonNormal
				title={titleIn}
				onClick={() => {
				  if (actualZoom > 1) {
					zoomTime(actualZoom - 1);
					setActualZoom(actualZoom - 1);
				  }
				}}
			  >
				<ButtonText><Icon name={'minus-circle-fill'} size="lg" /></ButtonText>
			  </ButtonNormal>
			)}
			{options.showTimeZoomButtons && (
			  <ButtonNormal
				title={titleOut}
				onClick={() => {
				  if (actualZoom < 7) {
					zoomTime(actualZoom + 1);
					setActualZoom(actualZoom + 1);
				  }
				}}
			  >
				<ButtonText><Icon name={'plus-circle-fill'} size="lg" /></ButtonText>
			  </ButtonNormal>
			)}
		  </ButtonContainer>
		)}
		{groupedChartLines.length > 0 && (
		  <ChartContainer style={{ left: toolBoxWidth + 5, bottom: 0  }}>
		    <ResponsiveContainer width={wrapperWidth - toolBoxWidth - 5} height="95%">
		      <ComposedChart
			    style={{ width: '100%', height: '100%' }}
			    width={wrapperWidth - toolBoxWidth}
			    height={height}
			    data={groupedChartLines}
			    layout={options.orientation}
			    syncId={syncID}
			    margin={chartMargin}
			    onMouseDown={(e) => {
				  if (options.allowZoom && !startZoom) {
				    setRefAreaLeft(e.activePayload[0].payload.date);
				    setRefAreaLeftLabel(e.activeLabel);
				    setRefAreaRightLabel(e.activeLabel);
				    setStartZoom(true);
				  }
			    }}
			    onMouseMove={(e) => {
				  if (options.allowZoom && startZoom) {
				    if ((Math.abs(refAreaLeftLabel - e.activeLabel) / (axisXEndIndex - axisXStartIndex)) > 0.025) {
				      setRefAreaRightLabel(e.activeLabel);
				    }
				  }
			    }}
			    onMouseUp={(e) => {
				  if (options.allowZoom && startZoom && refAreaLeftLabel > 0 && refAreaRightLabel > 0) {
				    zoom(refAreaLeft, e.activePayload[0].payload.date);
				    setRefAreaLeftLabel(0);
				    setStartZoom(false);
				  } else if (startZoom) {
				    setStartZoom(false);
				  }
			    }}
		      >
			   <defs>
		          {stackElements.map(stack => (
				    <linearGradient id={'line_horizontal_' + stack.stackId} x1="0" y1="0" x2="0" y2="1">
				      <stop offset="0%" stopColor={stack.color} stopOpacity={options.fillOpacityStart} />
				      <stop offset="95%" stopColor={stack.color} stopOpacity={options.fillOpacityEnd} />
				    </linearGradient>
			      ))}
				  <linearGradient id={'auxAreaColor_horizontal_' + id} x1="0" y1="0" x2="0" y2="1">
				   <stop offset="0%" stopColor={options.auxAreaColor} stopOpacity={options.fillOpacityStart} />
				    <stop offset="95%" stopColor={options.auxAreaColor} stopOpacity={options.fillOpacityEnd} />
			      </linearGradient>
			    </defs>
			    {!options.useZoom && (
				  <XAxis dataKey="id" type="number" hide={true} domain={[axisXStartIndex, axisXEndIndex - 1]} />
			    )}
			    {options.useZoom && (
				  <XAxis dataKey="id" type="number" hide={true} />
			    )}
			    {options.showLegend && (
			      <Legend
				    wrapperStyle={{ fontSize: options.fontSize, left: 0, top: 5 }}
				    formatter={renderColorfulLegendText}
				    iconSize={options.fontSize + 5}
				    iconType={'circle'}
				    verticalAlign={'top'}
				    margin={{ top: 10, left: 0, right: 0, bottom: 10 }}
				    onClick={selectLine}
			      />
			    )}
			    {groupedChartLines.length > axisXEndIndex && (
				  <XAxis
				    xAxisId="date"
				    dataKey="name"
				    type="category"
				    scale={'band'}
				    tick={{ fontSize: options.fontSize, fill: tickFontColor, transform: 'translate(0, 2)' }}
				    interval="preserveStartEnd"
				    ticks={groupedChartLines.length > 40 && axisXStartIndex > groupedChartLines.length - 10 ?
				      [groupedChartLines[axisXStartIndex + 10].name, groupedChartLines[axisXEndIndex - 10].name] :
				      [groupedChartLines[axisXStartIndex + 1].name, groupedChartLines[axisXEndIndex - 1].name]
				    }
				    hide={!options.showAxisX}
				    axisLine={{ stroke: axisLineColor, strokeWidth: axisLineWidth }}
				    tickLine={{ stroke: 'orange', strokeWidth: 1, transform: 'translate(0, 2)' }}
			      />
				)}
			    {axisXEndIndex >= groupedChartLines.length && (
				  <XAxis
				    xAxisId="date"
				    dataKey="name"
				    type="category"
				    scale={'band'}
				    tick={{ fontSize: options.fontSize, fill: tickFontColor, transform: 'translate(0, 2)' }}
				    interval="preserveStartEnd"
				    hide={!options.showAxisX}
				    axisLine={{ stroke: axisLineColor, strokeWidth: axisLineWidth }}
				    tickLine={{ stroke: 'orange', strokeWidth: 1, transform: 'translate(0, 2)' }}
			      />
				)}
			    {stackElements.map(stack => (
			      <YAxis
				    yAxisId="mainline"
				    dataKey={stack.stackId}
				    type="number"
				    orientation="left"
				    allowDecimals={axisLYEndIndex >= 1 ? false : true}
				    scale={options.scaleType}
				    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
				    tickFormatter={DataFormater}
				    interval="preserveEnd"
				    hide={!options.showAxisY}
				    domain={options.scaleType === 'log' ? ['auto', 'auto'] : [axisLYStartIndex, axisLYEndIndex]}
				    axisLine={{ stroke: axisLineColor, strokeWidth: axisLineWidth }}
				    tickLine={{ stroke: 'orange', strokeWidth: 1, transform: 'translate(0, 2)' }}
			      />
			    ))}
			    {options.showLineOnChart && (
			      <YAxis
			  	    yAxisId={options.sameYAxis ? 'mainline' : 'auxline'}
				    dataKey="auxline"
				    type="number"
				    orientation={options.sameYAxis ? 'left' : 'right'}
				    allowDecimals={axisRYEndIndex >= 1 ? false : true}
				    scale={options.scaleType}
				    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
				    tickFormatter={DataFormater}
				    interval="preserveEnd"
				    hide={!options.showAxisY}
				    domain={options.scaleType === 'log' ? ['auto', 'auto'] : [axisRYStartIndex, axisRYEndIndex]}
				    axisLine={{ stroke: axisLineColor, strokeWidth: axisLineWidth }}
				    tickLine={{ stroke: 'orange', strokeWidth: 1, transform: 'translate(0, 2)' }}
			      />
			    )}
			    {options.showAreaOnChart && !options.showLineOnChart && (
			      <YAxis
				    yAxisId={options.sameYAxis ? 'mainline' : 'auxline'}
				    dataKey="auxarea"
				    type="number"
				    orientation={options.sameYAxis ? 'left' : 'right'}
				    allowDecimals={axisRYEndIndex > 1 ? false : true}
				    scale={options.scaleType}
				    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
				    tickFormatter={DataFormater}
				    interval="preserveEnd"
				    hide={!options.showAxisY}
				    domain={options.scaleType === 'log' ? ['auto', 'auto'] : [axisRYStartIndex, axisRYEndIndex]}
				    axisLine={{ stroke: axisLineColor, strokeWidth: axisLineWidth }}
				    tickLine={{ stroke: 'orange', strokeWidth: 1, transform: 'translate(0, 2)' }}
			      />
			    )}
			    {options.allowZoom && (
			      <Bar
			        yAxisId={'mainline'}
				    dataKey="maxvalue"
				    legendType="rect"
				    name="Zoom"
				    label={false}
			        fill="#ffffff00"
			        stroke="#ffffff00"
			        strokeWidth={0}
				    isAnimationActive={false}
			      />
			    )}
			    {options.showAreaOnChart && (
			      <Area
				    yAxisId={options.sameYAxis ? 'mainline' : 'auxline'}
				    type={options.lineType}
				    name={options.auxAreaTitle}
				    unit={options.auxAreaUnit}
				    dataKey="auxarea"
				    fill={'url(#auxAreaColor_horizontal_' + id + ')'}
				    stroke={options.auxAreaColor}
				    strokeWidth={options.lineWidth}
				    hide={lineProps[options.auxAreaTitle] === true}
				    activeDot={showActiveDot === true ? true : false}
				    dot={showDot ? {
				      stroke: options.auxAreaColor, 
					  strokeWidth: options.lineWidth,
					  fill: backgroundColor
				    } : false}
				    isAnimationActive={renderCount.current > 1 ? false : options.isAnimationActive}
			      />
			    )}
			    {areaFactory(true)}
			    {options.showLineOnChart && (
			      <Line
				    yAxisId={options.sameYAxis ? 'mainline' : 'auxline'}
				    type={options.lineType}
				    name={options.auxLineTitle}
				    unit={options.auxLineUnit}
				    dataKey="auxline"
				    style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
				    stroke={options.auxLineColor}
				    strokeWidth={options.lineWidth}
				    hide={lineProps[options.auxLineTitle] === true}
				    activeDot={showActiveDot === true ? true : false}
				    dot={showDot ? {
				      stroke: options.auxLineColor, 
					  strokeWidth: options.lineWidth,
					  fill: backgroundColor
				    } : false}
				    style={{ filter: `drop-shadow(0px 2px 3px ${options.auxLineColor})` }}
				    isAnimationActive={renderCount.current > 1 ? false : options.isAnimationActive}
			      />
			    )}
			    {options.showTendence && axisXEndIndex > 1 && (
				  <Line
				    yAxisId={'mainline'}
				    type={options.lineType}
				    name={'Tendencia [' + delta + '%]'}
				    unit={options.metricUnit}
				    dataKey={'slope'}
				    style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
				    stroke={'blue'}
				    strokeWidth={options.lineWidth + 1}
				    hide={lineProps[options.metricTitle] === true}
				    strokeDasharray="3 3"
				    activeDot={showActiveDot === true ? true : false}
				    dot={showDot ? {
				      stroke: 'blue', 
					  strokeWidth: options.lineWidth,
					  fill: backgroundColor
				    } : false}
				    isAnimationActive={renderCount.current > 1 ? false : options.isAnimationActive}
			      />
			    )}
			    {options.showMetricOnChart && (
				  <Line
				    yAxisId={'mainline'}
				    type={options.lineType}
				    name={options.metricTitle}
				    unit={options.metricUnit}
				    dataKey={'maxmetric'}
				    style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
				    stroke={options.metricLineColor}
				    strokeWidth={options.lineWidth}
				    hide={lineProps[options.metricTitle] === true}
				    strokeDasharray="5 5"
				    activeDot={showActiveDot === true ? true : false}
				    dot={showDot ? {
				      stroke: options.metricLineColor, 
					  strokeWidth: options.lineWidth,
					  fill: backgroundColor
				    } : false}
				    isAnimationActive={renderCount.current > 1 ? false : options.isAnimationActive}
			      />
			    )}
			    {(startZoom && refAreaLeftLabel > 0 && refAreaRightLabel > 0) ? (
			      <ReferenceArea
				    yAxisId={'mainline'}
				    x1={refAreaLeftLabel}
				    y1={axisLYStartIndex}
				    x2={refAreaRightLabel}
				    y2={axisLYEndIndex}
				    stroke="#0d0deb"
				    strokeWidth={1}
				    fill="#0d0deb"
				    strokeOpacity={0.5}
				    label="Zoom"
				    ifOverflow="extendDomain"
				    isFront={true}
			      />
			    ) : null}
			    {options.useCartesianGrid && <CartesianGrid strokeDasharray="3 3" />}
			    {options.showTooltip && useNormalTooltip && (
			      <Tooltip
				    content={<NormalTooltip />}
				    cursor={{ stroke: isDark ? '#F4F9FF' : '#141618', strokeWidth: 1 }}
				    isAnimationActive={false}
				  />
			    )}
			    {options.useZoom && (
			      <Brush
				    dataKey="dateLocale"
				    height={15}
				    stroke={borderColor}
				    strokeWidth={1}
				    startIndex={axisXStartIndex}
				    endIndex={axisXEndIndex}
			      />
			    )}
		      </ComposedChart>
		    </ResponsiveContainer>
		  </ChartContainer>
		)}
	  </LineChartToolboxContainer>
	);
  }
});

function handleAuxClick(variable: string, value: string) {
  if (variable !== '' && value !== '') {
	const queryMap = {
	  [`var-${variable}`]: value,
	};
	locationService.partial(queryMap, true);
  }
}

function handleClick(url: string, target: string) {
  if (url !== null && url !== '') {
    window.open(url, target);
  }
}

function CustomTooltip(label: string, showTooltip: boolean, payload: any, options: any, clicEnable: boolean) {
  const LineTooltip = styled.div`
    font-size: 11px;
    line-height: 20px;
    color: #333333;
    width: fit-content;
    padding: 5px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 4px 2px 5px 2px #8b98a499;
    background-clip: padding-box;
    pointer-events: none;
    text-anchor: middle;
  `;
  const LineTooltipWarn = styled.div`
    font-size: 11px;
    line-height: 20px;
    color: #000000;
    width: fit-content;
    padding: 5px;
    background-color: #e45858;
    border: 1px solid red;
    border-radius: 4px;
    box-shadow: 4px 2px 5px 2px #8b98a499;
    background-clip: padding-box;
    pointer-events: none;
    text-anchor: middle;
  `;
  const LineChartTooltipCenter = styled.p`
    text-align: center;
  `;

  const LineChartTooltipText = styled.p`
    margin-top: 0px;
    margin-bottom: 0px;
  `;

  const LineChartTooltipTitle = styled.b`
    font-size: 12px;
    font-weight: 500;
    padding-left: 10px;
  `;

  if (payload && payload.length > 0) {
	let isOnFire = false;
	payload.forEach(segment => {
	  if (segment.payload[`onfire_${segment.dataKey}`]) {
	    isOnFire = true;
	  }
	});
	if (isOnFire) {
	  return (
		<LineTooltipWarn>
		  <span>
			<LineChartTooltipCenter>
			  <i className="fa fa-exclamation-triangle fa-2" aria-hidden="true"></i>
			  {options.timeSeriesChart || label === undefined || Label === null ? 
				<LineChartTooltipTitle>{'Alarma'}</LineChartTooltipTitle> :
				<LineChartTooltipTitle>{'Alarma en ' + label}</LineChartTooltipTitle>
			  }
			</LineChartTooltipCenter>
		  </span>
		  {options.showMetricOnTooltip && (
			<span>
			  {payload.length > 1 && payload.map(segment => (
				<div>
				  <span>
				    {!segment.payload[`onfire_${segment.dataKey}`] && segment.payload[`label_${segment.dataKey}`] && (
					  <LineChartTooltipText>
				        {segment.payload[`label_${segment.dataKey}`] + options.resultSeparator + ' '}
				        <b>{DataFormater(segment.value) + segment.unit}</b>
					    {' [' + options.metricTitle + options.resultSeparator + ' ' + DataFormater(segment.payload[`metric_${segment.dataKey}`]) + segment.unit + ']'}
				      </LineChartTooltipText>
				    )}
				    {segment.payload[`onfire_${segment.dataKey}`] && segment.payload[`label_${segment.dataKey}`] && (
					  <LineChartTooltipText>
				        <b><u>
						  {segment.payload[`label_${segment.dataKey}`] + options.resultSeparator + ' '}
				          {DataFormater(segment.value) + segment.unit}
						  {' [' + options.metricTitle + options.resultSeparator + ' ' + DataFormater(segment.payload[`metric_${segment.dataKey}`]) + segment.unit + ']'}
					    </u></b>
				      </LineChartTooltipText>
				    )}
				  </span>
				</div>
			  ))}
			  {payload.length === 1 && payload.map(segment => (
				<div>
				  <span>
				    <LineChartTooltipText>
				      <b>
						{segment.payload[`label_${segment.dataKey}`] + options.resultSeparator + ' '}
				        {DataFormater(segment.value) + segment.unit}
					  </b>
					</LineChartTooltipText>
					<LineChartTooltipText>
					  <b>
						{options.metricTitle + options.resultSeparator + ' '}
						{DataFormater(segment.payload[`metric_${segment.dataKey}`]) + segment.unit}
					  </b>
				    </LineChartTooltipText>
				  </span>
				</div>
			  ))}
			</span>
		  )}
		  {options.showMetricOnTooltip && options.showAreaOnChart && (
			<span>
			  <LineChartTooltipText>
				{options.auxAreaTitle + options.resultSeparator + ' '}
				<b>{DataFormater(payload[0].payload.auxarea)+ ' ' + options.auxAreaUnit}</b>
			  </LineChartTooltipText>
			</span>
		  )}
		  {options.showMetricOnTooltip && options.showLineOnChart && (
			<span>
			  <LineChartTooltipText>
				{options.auxLineTitle + options.resultSeparator + ' '}
				<b>{DataFormater(payload[0].payload.auxline)+ ' ' + options.auxLineUnit}</b>
			  </LineChartTooltipText>
			</span>
		  )}
		  {!options.showMetricOnTooltip && !options.showLabels && !options.showAxisY && !options.showAxisX && (
			<span>
			  {payload.length > 1 && payload.map(segment => (
				<div>
				  <span>
				    {!segment.payload[`onfire_${segment.dataKey}`] && (
					  <LineChartTooltipText>
				        {segment.payload[`label_${segment.dataKey}`] + options.resultSeparator + ' '}
				        <b>{DataFormater(segment.value) + segment.unit}</b>
				      </LineChartTooltipText>
					)}
				    {segment.payload[`onfire_${segment.dataKey}`] && (
					  <LineChartTooltipText>
				        <b><u>
						  {segment.payload[`label_${segment.dataKey}`] + options.resultSeparator + ' '}
				          {DataFormater(segment.value) + segment.unit}
						  {' [' + options.metricTitle + options.resultSeparator + ' ' + DataFormater(segment.payload[`metric_${segment.dataKey}`]) + segment.unit + ']'}
					    </u></b>
				      </LineChartTooltipText>
					)}
				  </span>
				</div>
			  ))}
			  {payload.length === 1 && payload.map(segment => (
				<div>
				  <span>
				    <LineChartTooltipText>
				      <b>
						{segment.payload[`label_${segment.dataKey}`] + options.resultSeparator + ' '}
				        {DataFormater(segment.value) + segment.unit}
					  </b>
				    </LineChartTooltipText>
				  </span>
				</div>
			  ))}
			</span>
		  )}
		  {!options.showMetricOnTooltip && (options.showLabels || options.showAxisY || options.showAxisX) && (
			<span>
			  {payload.map(segment => (
				<LineChartTooltipText>
				  {segment.payload[`label_${segment.dataKey}`]}
				</LineChartTooltipText>
			  ))}
			</span>
		  )}
		  {options.timeSeriesChart && (
			<span>
			  <LineChartTooltipText>
				{options.dateTitle + options.resultSeparator + ' '}
				<b>{payload[0].payload.dateLocale}</b>
			  </LineChartTooltipText>
			</span>
		  )}
		  {options.useInfoField && (
			<span>
			  {options.infoTitle + options.resultSeparator + ' '}
			  <b>{payload[0].payload.info}</b>
			</span>
		  )}
		  {clicEnable && (
			<p>
			  <LineChartTooltipText>{options.clicTitle}</LineChartTooltipText>
			</p>
		  )}
		</LineTooltipWarn>
	  );
	} else {
	  return (
		<LineTooltip>
		  {options.showMetricOnTooltip && (
			<span>
			  {payload.length > 1 && payload.map(segment => (
				<div>
				  {segment.payload[`label_${segment.dataKey}`] && (
				    <span>
				      <LineChartTooltipText>
				        <b>{segment.payload[`label_${segment.dataKey}`] + options.resultSeparator + ' '}</b>
				        {DataFormater(segment.value) + segment.unit}
					    {' [' + options.metricTitle + options.resultSeparator + ' ' + DataFormater(segment.payload[`metric_${segment.dataKey}`]) + segment.unit + ']'}
				      </LineChartTooltipText>
				    </span>
				  )}
				</div>
			  ))}
			  {payload.length === 1 && payload.map(segment => (
				<div>
				  <span>
				    <LineChartTooltipText>
				      <b>{segment.payload[`label_${segment.dataKey}`] + options.resultSeparator + ' '}</b>
				      {DataFormater(segment.value) + segment.unit}
					</LineChartTooltipText>
					<LineChartTooltipText>
					  <b>{options.metricTitle + options.resultSeparator + ' '}</b>
					  {DataFormater(segment.payload[`metric_${segment.dataKey}`]) + segment.unit}
				    </LineChartTooltipText>
				  </span>
				</div>
			  ))}
			</span>
		  )}
		  {options.showMetricOnTooltip && options.showAreaOnChart && (
			<span>
			  <LineChartTooltipText>
				<b>{options.auxAreaTitle + options.resultSeparator + ' '}</b>
				{DataFormater(payload[0].payload.auxarea)+ ' ' + options.auxAreaUnit}
			  </LineChartTooltipText>
			</span>
		  )}
		  {options.showMetricOnTooltip && options.showLineOnChart && (
			<span>
			  <LineChartTooltipText>
				<b>{options.auxLineTitle + options.resultSeparator + ' '}</b>
				{DataFormater(payload[0].payload.auxline)+ ' ' + options.auxLineUnit}
			  </LineChartTooltipText>
			</span>
		  )}
		  {!options.showMetricOnTooltip && !options.showLabels && !options.showAxisY && !options.showAxisX && (
			<span>
			  {payload.map(segment => (
				<LineChartTooltipText>
				  <b>{segment.payload[`label_${segment.dataKey}`] + options.resultSeparator + ' '}</b>
				  {DataFormater(segment.value) + ' ' + segment.unit}
				</LineChartTooltipText>
			  ))}
			</span>
		  )}
		  {!options.showMetricOnTooltip && (options.showLabels || options.showAxisY || options.showAxisX) && (
			<span>
			  {payload.map(segment => (
				<LineChartTooltipText>
				  {segment.payload[`label_${segment.dataKey}`]}
				</LineChartTooltipText>
			  ))}
			</span>
		  )}
		  {options.timeSeriesChart && (
			<span>
			  <LineChartTooltipText>
				<b>{options.dateTitle + options.resultSeparator + ' '}</b>
				{payload[0].payload.dateLocale}
			  </LineChartTooltipText>
			</span>
		  )}
		  {options.useInfoField && (
			<span>
			  <b>{options.infoTitle + options.resultSeparator + ' '}</b>
			  {payload[0].payload.info}
			</span>
		  )}
		  {clicEnable && (
			<p>
			  <LineChartTooltipText>{options.clicTitle}</LineChartTooltipText>
			</p>
		  )}
		</LineTooltip>
	  );
	}
  } else {
	return null;
  }
}


function DataFormater(value: any) {
  if (isNaN(value)) {
	return value.toFixed(2) + ' ';
  } else if (value >= 1000000000) {
	return (value / 1000000000).toFixed(2) + ' G';
  } else if (value >= 1000000) {
	return (value / 1000000).toFixed(2) + ' M';
  } else if (value >= 1000) {
	return (value / 1000).toFixed(2) + ' K';
  } else {
	return value.toFixed(2) + ' ';
  }
}

function zoomTime(actualZoom: number) {
  const d = Date.now();
  let to = new Date(d).toUTCString();
  let from = new Date(d - 10800000).toUTCString();
  switch (actualZoom) {
    case 2:
      from = new Date(d - 86400000).toUTCString();
      break;
    case 3:
      from = new Date(d - 86400000 * 7).toUTCString();
      break;
    case 4:
      from = new Date(d - 86400000 * 30).toUTCString();
      break;
    case 5:
      from = new Date(d - 86400000 * 90).toUTCString();
      break;
    case 6:
      from = new Date(d - 86400000 * 180).toUTCString();
      break;
    case 7:
      from = new Date(d - 86400000 * 360).toUTCString();
      break;
  }
  zoom(from, to);
}

function zoom(refRight: string, refLeft: string) {
  var time_from = new Date(refLeft).getTime();
  var time_to = new Date(refRight).getTime();
  if (time_from < time_to) {
    let queryMap: UrlQueryMap = { from: time_from, to: time_to };
    getLocationSrv().update({
      partial: true,
      replace: true,
      query: queryMap,
    });
  } else if (time_from > time_to) {
    let queryMap: UrlQueryMap = { from: time_to, to: time_from };
    getLocationSrv().update({
      partial: true,
      replace: true,
      query: queryMap,
    });
  }
}

function getSlope(evolutionLine) {
  let sumaX = 0;
  let sumaY = 0;
  let sumaXY = 0;
  let sumaXCuadrado = 0;

  for (let i = 0; i < evolutionLine.length; i++) {
    sumaX += i;
    sumaY += evolutionLine[i].value;
    sumaXY += i * evolutionLine[i].value;
    sumaXCuadrado += i * i;
  }

  const n = evolutionLine.length;
  const slope = (n * sumaXY - sumaX * sumaY) / (n * sumaXCuadrado - sumaX * sumaX);
  const interception = (sumaY - slope * sumaX) / n;

  const initialSlope = interception;
  const finalSlope = slope * (n - 1) + interception; // Calcula el valor final usando la pendiente

  return { initialSlope, finalSlope };
}